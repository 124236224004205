import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import AftercareContent from "../containers/AftercarePageContent"
import ChangeYourLife from "../containers/ChangeYourLife"
import Layout from "../containers/Layout"
import PageTitle from "../containers/PageTitle"
import SEO from "../components/SEO"
import { ContentWrapper } from "../containers/elegant.style"

const afterCareTemplate = ({ data }) => {
  const {
    content,
    seoDetails,
    title,
    subtitle,
    slug,
    faqs,
  } = data.contentfulAftercare

  return (
    <>
      <SEO
        title={seoDetails.metaTitle}
        shortDesc={seoDetails.metaShortDescription}
        description={seoDetails.metaDescription}
      />
      <Layout>
        <ContentWrapper>
          <PageTitle title={title} subtitle={subtitle} />
          <AftercareContent content={content} />
          <ChangeYourLife
            whiteBG
            title="Ready to change your life?"
            text="We’re here to help you feel your best. Explore our services, get in touch and book your consultation today."
          />
        </ContentWrapper>
      </Layout>
    </>
  )
}

export default afterCareTemplate

export const afterCareTemplateQuery = graphql`
  query aftercarePageBySlug($slug: String!) {
    contentfulAftercare(slug: { eq: $slug }) {
      title
      subtitle
      slug
      content {
        json
      }
      seoDetails {
        metaTitle
        metaShortDescription
        metaDescription
      }
    }
  }
`
