import styled from "styled-components"
import { themeGet } from "@styled-system/theme-get"

const ChangeYourLifeArea = styled.div`
  width: 100%;
  overflow: hidden;
  padding: 140px 0;
  background: ${props => (props.whiteBG ? "white" : "#fbfbf9")};
  @media only screen and (max-device-width: 1280px) {
    padding: 120px 0;
  }
  @media only screen and (max-width: 480px) {
    padding: 60px 0 60px 0;
  }
`

export const ContentWrapper = styled.div`
  max-width: 722px;
  margin: 0 auto;
  text-align: center;

  img {
    display: inline-block;
    margin-bottom: 5px;
    height: 100px;
    margin-left: 20px;
    @media only screen and (max-width: 667px) {
      margin-bottom: 20px;
      max-width: 50%;
      height: 80px;
    }
  }

  h2 {
    font-size: 32px;
    font-weight: 300;
    letter-spacing: 1px;
    color: ${themeGet("colors.textColor", "#333333")};

    @media only screen and (max-width: 1366px) {
      line-height: 40px;
      margin-bottom: 20px;
    }
    @media only screen and (max-width: 667px) {
      line-height: 38px;
      margin-bottom: 15px;
      padding: 0 15px;
    }
  }

  p {
    color: ${themeGet("colors.textColor", "#333333")};
    line-height: 28px;
    padding: 0 15px;
  }
`

export const ButtonGroup = styled.div`
  .reusecore__button {
    text-transform: inherit;
    &:first-child {
      color: ${themeGet("colors.white", "#fff")};
      background-color: ${themeGet("colors.secondaryBotton", "#886638")};
      border: 1px solid ${themeGet("colors.secondaryBotton", "#886638")};
      &:hover {
        background-color: #fbfbf9;
        border: 1px solid ${themeGet("colors.secondaryBotton", "#886638")};
        color: ${themeGet("colors.secondaryBotton", "#886638")};
      }
    }
  }
`

export default ChangeYourLifeArea
