import Fade from "react-reveal/Fade"
import PropTypes from "prop-types"
import React from "react"
import { BLOCKS } from "@contentful/rich-text-types"
/* eslint-disable react/display-name */
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

import Button from "../../components/Button"
import Container from "../../components/UI/Container"
import AboutContentWrapper, {
  ButtonGroup,
  ContentSection,
} from "./aftercareContent.style"

const AftercareContent = ({ content, slug }) => {
  const options = {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: (node, children) => (
        <img src={node.data.target.fields.file["en-US"].url} alt="blogpost" />
      ),
    },
  }
  return (
    <AboutContentWrapper>
      <Container width="1805px">
        <Fade up delay={100}>
          <ContentSection>
            {documentToReactComponents(content.json, options)}
            <ButtonGroup>
              <a
                href="https://bookings.gettimely.com/elegantbeautyclinic/bb/purchase?giftVoucherId=172213"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button title="Book your next Treatment" />
              </a>
            </ButtonGroup>
          </ContentSection>
        </Fade>
      </Container>
    </AboutContentWrapper>
  )
}

// MainImage style props
AftercareContent.propTypes = {
  content: PropTypes.object,
}

// MainImage default style
AftercareContent.defaultProps = {
  imageWrapper: {
    display: " block",
  },
}

export default AftercareContent
