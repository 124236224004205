import Fade from "react-reveal/Fade"
import React from "react"

import Button from "../../components/Button"
import Heading from "../../components/Heading"
import Image from "../../components/Image"
import Text from "../../components/Text"
import aesthetics from "../../common/assets/image/elegant/changeYourLife/aesthetics.png"
import heart from "../../common/assets/image/elegant/changeYourLife/heart.png"
import slim from "../../common/assets/image/elegant/changeYourLife/slim.png"
import ChangeYourLifeArea, {
  ButtonGroup,
  ContentWrapper,
} from "./changeYourLife"

const ChangeYourLife = ({ whiteBG, title, text }) => {
  return (
    <ChangeYourLifeArea whiteBG={whiteBG}>
      <ContentWrapper>
        <Fade up delay={200}>
          <div className="image-wrapper">
            <Image
              srcSet={`${aesthetics} 300w, ${aesthetics} 768w, ${aesthetics} 1280w`}
              src={aesthetics}
              alt="aesthetics"
              style={{ width: "66px", height: "107.03px" }}
            />
            <Image
              srcSet={`${heart} 300w, ${heart} 768w, ${heart} 1280w`}
              src={heart}
              alt="heart"
              style={{ width: "160px", height: "100px" }}
            />
            <Image
              srcSet={`${slim} 300w, ${slim} 768w, ${slim} 1280w`}
              src={slim}
              alt="slim"
              style={{ width: "82px", height: "100px" }}
            />
          </div>
        </Fade>
        <Fade up delay={300}>
          <Heading content={title} />
          <Text content={text} />
        </Fade>
        <Fade up delay={200}>
          <ButtonGroup>
            <a
              href="https://bookings.gettimely.com/elegantbeautyclinic/bb/book"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button title="Book a treatment" />
            </a>
          </ButtonGroup>
        </Fade>
      </ContentWrapper>
    </ChangeYourLifeArea>
  )
}

export default ChangeYourLife
